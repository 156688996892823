import React from 'react';
import { useLocation } from 'react-router-dom';
import { General } from './general.routes';
import { Backoffice } from './backoffice.routes';
import { LocalStorageService, TOKEN } from '../services/LocalStorageService';

const getTokenFromLocalStorage = (): string | null => {
  try {
    return LocalStorageService.get<string>(TOKEN);
  } catch (error) {
    // Handle any potential errors when accessing localStorage
    console.error('Error accessing localStorage:', error);
    LocalStorageService.clearAll();
    return null;
  }
};

const hasSysadminPath = (path: string): boolean => {
  return path.includes('sysadmin') || path.includes('sysadmin/home'); // home is deprecated, but some clients still use it
};

export const Routes = () => {
  const location = useLocation();
  const isPrivateRoute = getTokenFromLocalStorage() && hasSysadminPath(location.pathname);

  return isPrivateRoute ? <Backoffice /> : <General />;
};
