import { WhatsappOrder } from "../../../../models/DataResponse";
import { Role } from "../../../../models/Enum";
import { OrderStatus } from "../../../../models/OrderStatus";
import { DeliveryType } from "../../../../utils/DeliveryUtil";

interface UseShouldRenderSelectDriverProps {
  order: WhatsappOrder;
  role: string;
}

export const useShouldRenderSelectDriver = ({order, role}: UseShouldRenderSelectDriverProps) => {
  const isDelivery = order.client.deliveryType === DeliveryType.DELIVERY;
  const isAllowedRole = role === Role.USER || role === Role.MANAGER;
  const shouldRenderSelectDriver = isDelivery && isAllowedRole && (order.status === OrderStatus.IN_PROGRESS || order.status === OrderStatus.READY);

  return { shouldRenderSelectDriver };
}