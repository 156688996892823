
import { Drawer, Layout } from "antd";
import React, { useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Route, Routes, useNavigate } from "react-router-dom";
import packageJson from '../../package.json';

import { PageNotFound } from "../components/PageNotFound";
import { SideBarMenu } from "../components/SideBarMenu";
import { SideBarMenuContent } from "../components/SideBarMenu/components/SideBarMenuContent";
import { TopBar } from "../components/TopBar";
import { BreakPoints, useBreakpoint, useListProductsOutOfStock, useLoggedUser, useModal, useUnreadContactsCounter } from "../hooks";
import { Role } from "../models/Enum";
import { ActivityLogsPage } from "../pages/backoffice/ActivityLogs";
import { AdminActivityLogPage } from "../pages/backoffice/AdminActivityLog";
import CategoryPage from "../pages/backoffice/Category";
import { CompanyPage } from "../pages/backoffice/Companies";
import { ContactPage } from "../pages/backoffice/Contacts";
import { CouponPage } from "../pages/backoffice/Coupon";
import { FeedbackPage } from "../pages/backoffice/Feedback";
import { HistoryOrders } from "../pages/backoffice/HistoryOrders";
import { OrdersPage } from "../pages/backoffice/Orders";
import { OrdersDriverPage } from "../pages/backoffice/OrdersDriver";
import { ProductsPage } from "../pages/backoffice/Products";
import PushInformationPage from "../pages/backoffice/PushInformation";
import { QRcodeGeneratorPage } from "../pages/backoffice/QRcode";
import { QRLinksPage } from "../pages/backoffice/QRLinks";
import { SettingsDefaultTextsPage } from "../pages/backoffice/Settings/SettingsDefaultTexts";
import { SettingsFeedbackPage } from "../pages/backoffice/Settings/SettingsFeedback";
import { SettingsManagerPage } from "../pages/backoffice/Settings/SettingsManager";
import { SettingsOrdersPage } from "../pages/backoffice/Settings/SettingsOrders";
import { SettingsProfilePage } from "../pages/backoffice/Settings/SettingsProfile";
import { SettingsStorePage } from "../pages/backoffice/Settings/SettingsStore";
import { StorekeeperPage } from "../pages/backoffice/Storekeepers";
import { UserPage } from "../pages/backoffice/Users";
import OrderDetailsStaff from "../pages/backoffice/OrderDetailsStaff";

//TODO - review this style
import './styles.css';
import { PendingCompanyRegistrationPage } from "../pages/backoffice/Companies/containers/PendingCompanyRegistrationPage";

const { Content, Footer } = Layout;

export const Backoffice = () => {
  const { data: unreadContactsReader } = useUnreadContactsCounter();
  const { data: loggedUser, isError } = useLoggedUser();
  const { data: stock } = useListProductsOutOfStock(loggedUser?.user?.company);
  const navigate = useNavigate();

  const { breakPnt } = useBreakpoint()
  const { close, isOpen, toggle: toggleSideMenu } = useModal();

  const showSideMenu = useMemo(() => breakPnt === BreakPoints.LG, [breakPnt]);

  const getDefaultRoute = () => {
    const role = loggedUser?.user?.role;

    if (role === Role.ADMIN) {
      return <CompanyPage />
    } else if (role === Role.MANAGER || role === Role.KITCHEN || role === Role.DRIVER) {
      return role === Role.DRIVER ? <OrdersDriverPage /> : <OrdersPage />
    }
    return <CategoryPage />;
  }

  if (isError) {
    confirmAlert({
      title: 'Sessão inválida',
      message: 'Você será redirecionado para efetuar um novo login.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {
            localStorage.clear();
            navigate('/session');
          },
        },
      ],
      closeOnClickOutside: false,
    });
  }

  if (!loggedUser) {
    return null;
  }

  return (
    <Layout style={{ minHeight: '100vh', minWidth: '374px' }}>
      {showSideMenu && (
        <SideBarMenu
          contactsBadgeCounter={unreadContactsReader?.total}
          stockCounter={{
            outOfStock: stock?.listOutOfStock?.length,
            lowStock: stock?.listLowStock?.length,
          }}
        />
      )}

      <Drawer
        placement="left"
        closable={false}
        onClose={close}
        open={isOpen}
        width={220}
        bodyStyle={{ background: '#15395b', padding: '0', height: '100vh' }}
      >
        <SideBarMenuContent
          contactsBadgeCounter={unreadContactsReader?.total}
          stockCounter={{
            outOfStock: stock?.listOutOfStock?.length,
            lowStock: stock?.listLowStock?.length,
          }}
          onMenuSelected={() => toggleSideMenu()}
        />
      </Drawer>

      <Layout className="site-layout">
        <TopBar
          companyInfo={loggedUser.companyInfo}
          onSideMenuClick={toggleSideMenu}
          username={loggedUser.user.name ?? '--'}
        />

        <Content style={{ margin: '0 16px' }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360, marginTop: '16px' }}
          >
            <Routes>
              <Route element={getDefaultRoute()} path="/sysadmin/" />
              <Route element={loggedUser.user.role === Role.ADMIN ? <AdminActivityLogPage /> : <ActivityLogsPage />} path="/sysadmin/activity-log" />
              <Route element={loggedUser.user.role === Role.ADMIN ? <CompanyPage /> : <StorekeeperPage />} path="/sysadmin/companies" />
              <Route element={loggedUser.user.role === Role.DRIVER ? <OrdersDriverPage /> : <OrdersPage />} path="/sysadmin/orders" />
              <Route element={<CategoryPage />} index path="/sysadmin/categories" />
              <Route element={<ContactPage />} path="/sysadmin/contacts" />
              <Route element={<CouponPage />} path="/sysadmin/coupons" />
              <Route element={<FeedbackPage />} path="/sysadmin/feedbacks" />
              <Route element={<HistoryOrders />} path="/sysadmin/history/orders" />
              <Route element={<OrderDetailsStaff />} path="/sysadmin/order-detail" />
              <Route element={<PendingCompanyRegistrationPage />} path="/sysadmin/companies/:uuid/pending" />
              <Route element={<ProductsPage />} path="/sysadmin/products" />
              <Route element={<PushInformationPage />} path="/sysadmin/banner" />
              <Route element={<QRcodeGeneratorPage />} path="/sysadmin/qrcode/generator" />
              <Route element={<QRLinksPage />} path="/sysadmin/qrlinks" />
              <Route element={<SettingsDefaultTextsPage />} path="/sysadmin/settings/default-texts" />
              <Route element={<SettingsFeedbackPage />} path="/sysadmin/settings/feedback" />
              <Route element={<SettingsManagerPage />} path="/sysadmin/settings/general" />
              <Route element={<SettingsOrdersPage />} path="/sysadmin/settings/orders" />
              <Route element={<SettingsProfilePage />} path="/sysadmin/settings/profile" />
              <Route element={<SettingsStorePage />} path="/sysadmin/settings/store" />
              <Route element={<UserPage />} path="/sysadmin/users" />
              <Route element={<PageNotFound />} path="*" />
            </Routes>
          </div>
        </Content>

        <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()} QRcode Preferido. All Rights Reserved. (v{packageJson.version})</Footer>
      </Layout>
    </Layout>
  );
};