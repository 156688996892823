import { Role } from "@/models/Enum";

interface UseRolesDropdownItemsProps {
  currentRole: Role;
  hasSellsModule: boolean;
}

/*
  - Super Admin is only possible via API by the system admin
*/
export const useRolesDropdownItems = ({ currentRole, hasSellsModule }: UseRolesDropdownItemsProps) => {
  if (currentRole === Role.USER && !hasSellsModule) {
    return [
      {
        value: Role.USER,
        label: 'Lojista',
      },
    ];
  }

  return [
    {
      value: Role.USER,
      label: 'Lojista',
      allowedRolesToCreate: [Role.ADMIN, Role.MANAGER, Role.USER],
    },
    {
      value: Role.KITCHEN,
      label: 'Cozinha',
      allowedRolesToCreate: [Role.ADMIN, Role.MANAGER, Role.USER],
    },
    {
      value: Role.DRIVER,
      label: 'Entregador/Estafeta',
      allowedRolesToCreate: [Role.ADMIN, Role.MANAGER, Role.USER],
    },
    {
      value: Role.MANAGER,
      label: 'Gerente',
      allowedRolesToCreate: [Role.ADMIN, Role.MANAGER],
    },
  ].filter((role) => role.allowedRolesToCreate.includes(currentRole));
};