import React from "react";
import { Space } from "antd";
import { EyeOutlined, PrinterFilled } from '@ant-design/icons';

import { WhatsappOrder } from "../../../../../../models/DataResponse";
import { TableGenericAction } from "../../../../../../components/TableGenericAction";
import { OrderStatus } from "../../../../../../models/OrderStatus";
import { dispatchOrderPrintRequest } from "../../../../../../utils/OrderUtil";
import { ActionButtons } from "./components/ActionButtons";
import { useUpdateOrderStatus } from "../../../hooks/useUpdateOrderStatus";
import { SelectDriverActionButton } from "./components/SelectDriverActionButton";
import { useShouldRenderSelectDriver } from "../../../hooks/useShouldRenderSelectDriver";

interface OrderTableActionsProps {
  currency: string;
  handleItemClick(order: WhatsappOrder): void;
  onSelectDriver(order: WhatsappOrder): void;
  order: WhatsappOrder;
  role: string;
  toggleAudio(): void;
}

export const OrderTableActions = ({
  currency,
  handleItemClick,
  onSelectDriver,
  order,
  role,
  toggleAudio,
}: OrderTableActionsProps) => {
  const { updateOrderStatus } = useUpdateOrderStatus();
  const acceptedOrder = order.status !== OrderStatus.PENDING;
  const { shouldRenderSelectDriver } = useShouldRenderSelectDriver({ order, role });

  return (
    <Space direction="vertical" key={order._id} style={{ width: '180px' }}>
      <TableGenericAction
        icon={<EyeOutlined style={{ fontSize: '18px' }} />}
        label="Detalhes"
        onClick={() => handleItemClick(order)}
      />

      {acceptedOrder && (
        <TableGenericAction
          dashed
          icon={<PrinterFilled style={{ fontSize: '18px' }} />}
          label="Imprimir"
          onClick={() => dispatchOrderPrintRequest(
            order._id,
            order.companyId,
            currency
          )}
        />
      )}

      {shouldRenderSelectDriver && (
        <SelectDriverActionButton onSelectDriver={() => onSelectDriver(order)} />
      )}

      <ActionButtons
        order={order}
        toggleAudio={toggleAudio} />

      {!(order.status === OrderStatus.CONCLUDED || order.status === OrderStatus.CANCELLED || order.status === OrderStatus.NOT_DELIVERED) && (
        <TableGenericAction
          dashed
          icon={<i
            className='fa fa-times'
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />}
          label={acceptedOrder ? 'Cancelar' : 'Recusar'}
          style={{
            backgroundColor: '#b82f2f',
            color: '#fff',
          }}
          onClick={() => {
            toggleAudio();
            updateOrderStatus(order, OrderStatus.CANCELLED);
          }}
        />
      )}
    </Space>
  );
}