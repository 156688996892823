import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserService } from '../components/services/UserService';
import { USERS } from './useUsers';
import { User } from '@/models/User';

export const useUser = () => {
  const queryClient = useQueryClient();

  const { mutate: mutateCreate, isLoading, isSuccess, isError } = useMutation(
    (user: User) => UserService.create(user)
  );

  const { mutate: mutateDelete } = useMutation(
    (id: string) => UserService.delete(id)
  );

  const { mutate: mutateUpdate } = useMutation(
    (user: User) => UserService.update(user)
  );

  const create = useCallback(
    (user, { onSuccess, onError }) => {
      mutateCreate(user, {
        onSuccess: (createdUser) => {
          const users = queryClient.getQueryData<User[]>(USERS) || [];
          queryClient.setQueryData<User[]>(USERS, [...users, createdUser]);

          onSuccess();
        },
        onError,
      });
    },
    [mutateCreate, queryClient]
  );

  const update = useCallback(
    (user, { onSuccess, onError }) => {
      mutateUpdate(user, {
        onSuccess: (updatedUser) => {
          queryClient.setQueryData<User[]>(
            USERS,
            (users) =>
              users?.map((user) =>
                user._id === updatedUser._id
                  ? updatedUser
                  : user
              ) ?? []
          );
          onSuccess();
        },
        onError,
      });
    },
    [mutateUpdate, queryClient]
  );

  const deleteUser = useCallback((id, { onSuccess, onError }) => {
    mutateDelete(id, {
      onSuccess: () => {
        onSuccess();
      },
      onError,
    });
  }, [mutateDelete]
  );

  return { create, deleteUser, isLoading, isSuccess, isError, update };
};
