import axios from 'axios';
import { baseURL } from './endpoints';
import { LocalStorageService, TOKEN } from './LocalStorageService';

const api = axios.create({ baseURL });

api.interceptors.request.use(function (config) {
  const token = LocalStorageService.get<string>(TOKEN);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default api;
