import React from 'react';
import { Button, Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface ForgotPasswordProps {
  isFetching: boolean;
  isRequired: boolean;
}

export const ForgotPassword = ({ isFetching, isRequired }: ForgotPasswordProps) => {
  return (
    <>
      <Form.Item
        name="email"
        label={<label className="label-input-field">Email</label>}
        rules={[{ required: isRequired, message: 'Campo email obrigatório' }]}
        style={{ marginTop: '16px' }}
      >
        <Input prefix={<UserOutlined />} placeholder="Digite o seu usuário" style={{ height: '40px' }} />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        style={{ width: '100%' }}
        loading={isFetching}
      >
        <b>Enviar</b>
      </Button>
    </>
  );
};
