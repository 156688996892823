export enum MenuOption {
  ACTIVITY_LOG = 'Registro de Atividades',
  CATEGORIES = 'Categorias',
  COMPANY = 'Empresas',
  CONTACT = 'Contatos',
  COUPON = 'Cupom de Desconto',
  FEEDBACK = 'Feedbacks Recebidos',
  HISTORY_ORDERS = 'Histórico dos Pedidos',
  PRODUCTS = 'Produtos',
  MY_LINK = 'Meu Link',
  QR_LINKS = 'QRLinks',
  BANNER_INFO = 'Banner Informativo',
  SETTINGS_ORDER = 'Pedidos',
  SETTINGS_DEFAULT_TEXT = 'Textos Padrões',
  SETTINGS_FEEDBACK = 'Feedback',
  SETTINGS_PROFILE = 'Minha Conta',
  SETTINGS_STORE = 'Geral',
  SETTINGS_GENERAL = 'Admin Geral',
  STORES = 'Lojas',
  QRCODE = 'Gerador QRcode',
  USER = 'Usuários',
  WHATSAPP_ORDERS = 'Pedidos de Hoje',
}
