import { Role } from "@/models/Enum";
import { adminMenuOptions } from "../components/AdminMenuOptions";
import { clientMenuOptions } from "../components/ClientMenuOptions";
import { kitchenMenuOptions } from "../components/KitchenMenuOptions";
import { driverMenuOptions } from "../components/DriverMenuOptions";
import { managerMenuOptions } from "../components/ManagerMenuOptions";
import { useLoggedUser } from "@/hooks";
import { MenuOption } from "@/models/MenuOption";

interface GetMenuItemsByRoleProps {
  collapsed?: boolean;
  contactsBadgeCounter?: number;
  stockCounter?: any;
}

export const useGetMenuItemsByRole = ({ collapsed = false, contactsBadgeCounter, stockCounter }: GetMenuItemsByRoleProps) => {
  const { userLogged } = useLoggedUser();
  const { companyInfo, user: { role } } = userLogged;
  const menuUrl = companyInfo?.menuUrl ?? 'clientes';

  const menuItems = () => {
    switch (role) {
      case Role.ADMIN:
        return adminMenuOptions(
          collapsed,
          contactsBadgeCounter,
        );

      case Role.USER:
        return clientMenuOptions(
          collapsed,
          menuUrl,
          stockCounter,
        );

      case Role.KITCHEN:
        return kitchenMenuOptions(collapsed);

      case Role.DRIVER:
        return driverMenuOptions(collapsed);

      case Role.MANAGER:
        return managerMenuOptions(collapsed);
    }
  }

  const defaultSelectedKeys = () => {
    let defaultKey = MenuOption.CATEGORIES;

    if (role === Role.ADMIN) {
      defaultKey = MenuOption.COMPANY;
    } else if (role === Role.MANAGER || role === Role.KITCHEN || role === Role.DRIVER) {
      defaultKey = MenuOption.WHATSAPP_ORDERS;
    }

    return [defaultKey];
  }

  return { menuItems, defaultSelectedKeys }
};
