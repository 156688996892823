import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactPixel from 'react-facebook-pixel';

interface HeaderMetadataProps {
  facebookPixelId?: string;
  favicon?: string;
  title?: string;
}

export const HeaderMetadata = ({ facebookPixelId, favicon, title }: HeaderMetadataProps) => {
  useEffect(() => {
    if (!facebookPixelId) return;

    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(facebookPixelId, undefined, options);
    ReactPixel.pageView();
    ReactPixel.track("ViewContent")
  }, [facebookPixelId]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      {title && <title>{title}</title>}
      {favicon && <link rel="icon" type="image/png" href={favicon} sizes="16x16"></link>}
    </Helmet>
  );
}
