import React, { useMemo, useState } from 'react'
import moment from 'moment';
import { Button, DatePicker, Divider, Form, Input } from 'antd';
import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';
import { FormSwitchItem } from '../../../../../../components/FormSwitchItem';
import { FormRadioGroup } from '../../../../../../components/FormRadioGroup';
import { OrderCountType } from '../../../../../../models/Enum';
import { FormItemDatePicker } from '../../../../../../common-styles';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../../../../utils/dateTime';
import { useLoggedUser, useUpdateCompanyFeatures } from '../../../../../../hooks';
import { NotificationDispatcher, NotificationType } from '../../../../../../components/Notification';
import { FormHelper } from '../../../../../../components/FormHelper';
import { MinOrderContainer } from '../../styles';
import { PREFIX_LOCALSTORAGE } from '../../../../../../services/LocalStorageService';
import { registerAction } from '@/services/internalAnalytics';

interface GeneralProps {
  companyFeatures: CompanyFeatures;
  handleCheckboxChange(checkedValue: boolean, switchId: string): void;
}

const requiredOrOptionalOptions = [
  { label: 'Obrigatório', value: true },
  { label: 'Opcional', value: false },
];

const orderTypeOptions = [
  { label: OrderCountType[0].label, value: true },
  { label: OrderCountType[1].label, value: false },
];

export const General = ({companyFeatures, handleCheckboxChange}: GeneralProps) => {
  const [minOrderPrice, setMinOrderPrice] = useState(0);
  const { update } = useUpdateCompanyFeatures();
  const { userLogged } = useLoggedUser();

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  
  const updateCompanyFeatures = (companyFeatures: CompanyFeatures) => {
    update(
      { ...companyFeatures },
      {
        onSuccess: () => NotificationDispatcher({
          message: 'Atualizado com sucesso.',
        }),
        onError: () => NotificationDispatcher({
          message: 'Erro ao tentar atualizar.',
          type: NotificationType.ERROR,
        })
      },
    );
  }

  return (
    <>
      <FormSwitchItem
        label="Cupom de desconto"
        propertyId="showCouponFeature"
        defaultChecked={
          companyFeatures.sellsConfiguration?.showCouponFeature ?? false
        }
        handleCheckboxChange={handleCheckboxChange}
        checkedValueText={'Ativo'}
        uncheckedValueText={'Pausado'}
        helper={
          'Controle se o cliente poderá utilizar o cupom de desconto.'
        }
      />

      <Divider />
      <FormSwitchItem
        label="Exibir informativo do uso do WhatsApp?"
        propertyId="showInformeSellsViaWhatsapp"
        defaultChecked={
          companyFeatures.showInformeSellsViaWhatsapp ?? true
        }
        handleCheckboxChange={(checked, propertyId) => {
          handleCheckboxChange(checked, propertyId);
          registerAction(`showInformeSellsViaWhatsapp | value: ${checked}`, userLogged);
        }}
        helper={
          'Será exibido as instruções de uso para ser fazer um pedido no catálogo/cardápio.'
        }
      />

      <Divider />
      <FormSwitchItem
        label="O seu negócio faz Delivery?"
        propertyId="hasDelivery"
        defaultChecked={
          companyFeatures.sellsConfiguration?.hasDelivery ?? true
        }
        handleCheckboxChange={handleCheckboxChange}
        helper={
          'Será ser exibido a opção de delivery no resumo de pedidos no catálogo/cardápio.'
        }
      />

      <Divider />
      <FormSwitchItem
        label="O cliente pode retirar o pedido no local?"
        propertyId="hasPickUp"
        defaultChecked={
          companyFeatures.sellsConfiguration?.hasPickUp ?? true
        }
        handleCheckboxChange={handleCheckboxChange}
        helper={
          'Será exibido a opção de retirada no local no resumo de pedidos no catálogo/cardápio.'
        }
      />

      <Divider />
      <FormSwitchItem
        label="O cliente pode comer no local?"
        propertyId="hasEatInLoco"
        defaultChecked={
          companyFeatures.sellsConfiguration?.hasEatInLoco ?? false
        }
        handleCheckboxChange={handleCheckboxChange}
        helper={
          'Será exibido a opção de comer no local no resumo de pedidos no catálogo/cardápio.'
        }
      />

      <Divider />
      <FormSwitchItem
        label="Exibir data do pré-agendamento?"
        propertyId="isDatetimeForDeliveryOrPickupVisible"
        defaultChecked={
          companyFeatures.sellsConfiguration?.isDatetimeForDeliveryOrPickupVisible ?? false
        }
        handleCheckboxChange={handleCheckboxChange}
        helper={
          'Opção que permite o seu cliente escolher a data pretendida do pré-agendamento do pedido/serviço, será exibida no resumo de pedidos no catálogo/cardápio.'
        }
      />

      {companyFeatures.sellsConfiguration?.isDatetimeForDeliveryOrPickupVisible && (
        <>
          <FormRadioGroup
            propertyId="isDatePickerRequired"
            options={requiredOrOptionalOptions}
            defaultValue={companyFeatures.sellsConfiguration?.isDatePickerRequired ?? false}
            handleOnChange={(value) => handleCheckboxChange(value, 'isDatePickerRequired')}
          />

          <FormItemDatePicker>
            <label className="label-input-field">
              Data de início
            </label>
            <DatePicker
              format={DATE_FORMAT}
              onChange={(date) => {
                let momentDate = date;
                if (!date) {
                  momentDate = moment();
                }
                companyFeatures.sellsConfiguration.startDatePicker = moment(momentDate).format(SERVER_DATE_FORMAT)
                updateCompanyFeatures(companyFeatures);
              }}
              disabledDate={(current) => current && current.valueOf() <= Date.now()}
              style={{ width: '20%' }}
            />

            <FormHelper label="Escolha a data de início. Por padrão, o sistema assume o dia atual." />
          </FormItemDatePicker>
        </>
      )}

      <Divider />
      <FormSwitchItem
        label="Exibir hora do pré-agendamento?"
        propertyId="isTimePickerForDeliveryOrPickupVisible"
        defaultChecked={
          companyFeatures.sellsConfiguration?.isTimePickerForDeliveryOrPickupVisible ?? false
        }
        handleCheckboxChange={handleCheckboxChange}
        helper={
          'Opção que permite o seu cliente escolher a hora pretendida do pré-agendamento do pedido/serviço, será exibida no resumo de pedidos no catálogo/cardápio.'
        }
      />

      {companyFeatures.sellsConfiguration?.isTimePickerForDeliveryOrPickupVisible && (
        <FormRadioGroup
          propertyId="isTimePickerRequired"
          options={requiredOrOptionalOptions}
          defaultValue={companyFeatures.sellsConfiguration?.isTimePickerRequired ?? false}
          handleOnChange={(value) => handleCheckboxChange(value, 'isTimePickerRequired')}
        />
      )}

      <Divider />
      <FormSwitchItem
        label="Enviar link de acompanhamento do pedido na mensagem do WhatsApp?"
        propertyId="sendLinkToFollowOrder"
        defaultChecked={
          companyFeatures.sellsConfiguration?.sendLinkToFollowOrder ?? false
        }
        handleCheckboxChange={handleCheckboxChange}
        helper={
          'Opção que permite enviarmos um link para acompanhamento do pedido. O link irá ser apresentado no rodapé da mensagem que é enviada para o WhatsApp, no momento do pedido.'
        }
      />

      <Divider />
      <FormRadioGroup
        label="Contagem dos itens cardápio/catálogo por:"
        propertyId="orderCountType"
        options={orderTypeOptions}
        defaultValue={companyFeatures.sellsConfiguration?.orderCountType === OrderCountType[0].value}
        handleOnChange={(checked) => {
          if (companyFeatures.sellsConfiguration) {
            companyFeatures.sellsConfiguration['orderCountType'] = checked
              ? OrderCountType[0].value
              : OrderCountType[1].value;
            updateCompanyFeatures(companyFeatures);
          }
        }}
      />

      <Divider />
      <Form.Item
        label={<label className="label-input-field">Pedido Mínimo</label>}
        name="minOrderPrice"
        colon={true}
        help={
          'Menor valor de um pedido, sem contar com a taxa de entrega. Esta funcionalidade é exclusiva para Delivery.'
        }
      >
        <MinOrderContainer>
          <Input
            type="number"
            addonBefore={currency}
            style={{ width: '20%', height: '40px', paddingRight: '8px' }}
            placeholder="0.00"
            defaultValue={companyFeatures.sellsConfiguration?.minOrderPrice}
            onChange={(e) => { setMinOrderPrice(Number(e.target.value)) }}
          />

          <Button
            type="primary"
            className='apply-button'
            onClick={() => {
              if (companyFeatures.sellsConfiguration) {
                companyFeatures.sellsConfiguration.minOrderPrice = Number(minOrderPrice);
                updateCompanyFeatures(companyFeatures);
              }
            }}>
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </MinOrderContainer>
      </Form.Item>
    </>
  )
}