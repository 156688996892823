import React from "react";
import { Button, Form } from "antd";
import { NotificationDispatcher, NotificationType } from "@/components/Notification";
import { CompanyFeatures } from "@/models/CompanyFeatures";
import { useUpdateCompanyFeatures } from "@/hooks";
import { FormInputField } from "@/components/FormInputField";

interface IntegrationsProps {
  companyFeatures: CompanyFeatures;
}

export const Integrations = ({ companyFeatures }: IntegrationsProps) => {
  const { isLoading, update } = useUpdateCompanyFeatures();

  const handleSave = (values: any) => {
    companyFeatures.facebookPixel = values.facebookPixel;

    update(companyFeatures, {
      onSuccess: () => {
        NotificationDispatcher({
          message: 'Operação realizada com sucesso.',
        });
      },
      onError: () => {
        NotificationDispatcher({
          message: 'Erro ao tentar proceder com a operação.',
          type: NotificationType.ERROR,
        });
      },
    });
  };

  return (
    <Form
      initialValues={companyFeatures}
      layout="vertical"
      onFinish={handleSave}
      {...{ wrapperCol: { span: 8 } }}
    >
      <FormInputField
        help="Insira apenas o pixel ID do Facebook"
        label="Pixel"
        name="facebookPixel"
        placeholder="Insira o pixel do Facebook"
      />

      <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }} loading={isLoading}>
        <i
          className="fa fa-check-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Salvar
      </Button>
    </Form>
  );
};
