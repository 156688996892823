import React from "react";
import { Dropdown } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";

interface TableContextMenuPros {
  items: ItemType[] | undefined
}

export const TableContextMenu = ({ items }: TableContextMenuPros) => (<Dropdown menu={{ items }}>
  <div style={{ cursor: 'pointer', width: 100 }}>
    <i
      className="fa fa-ellipsis-v"
      aria-hidden="true"
      style={{ fontSize: '18px' }}
    />
  </div>
</Dropdown>);
