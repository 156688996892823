import { useMutation } from "react-query";
import { ResetPassword } from "./ResetPassword";
import { PasswordService } from "./PasswordService";

export const useDefinePassword = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (resetPassword: ResetPassword) => PasswordService.resetPassword(resetPassword),
  })

  return {
    reset: (resetPassword: ResetPassword) => mutate(resetPassword),
    isLoading,
    isSuccess,
    isError,
  };
};
