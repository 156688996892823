import React from "react";
import { TableGenericAction } from "../../../../../../../../components/TableGenericAction";
import { WhatsappOrder } from "../../../../../../../../models/DataResponse";
import { useCompanyFeatures, useLoggedUser } from "../../../../../../../../hooks";
import { OrderStatus } from "../../../../../../../../models/OrderStatus";
import { sendMessageToWhatsapp } from "../../../../../Util/SendWhatsappMessage";
import { Role } from "../../../../../../../../models/Enum";
import { useUpdateOrderStatus } from "../../../../../hooks/useUpdateOrderStatus";

interface ReadyActionButtonProps {
  order: WhatsappOrder;
}

export const ReadyActionButton = ({ order }: ReadyActionButtonProps) => {
  const { userLogged } = useLoggedUser();
  const { user: { company, role } } = userLogged;
  const { companyFeatures } = useCompanyFeatures(company);
  const { updateOrderStatus } = useUpdateOrderStatus();

  return (
    <TableGenericAction
      dashed
      icon={<i className='fa fa-check'
        aria-hidden="true"
        style={{ marginRight: '10px' }}
      />}
      label='Pronto'
      style={{
        backgroundColor: '#2f78b8',
        color: '#fff',
      }}
      onClick={() => {
        const message = order.client.address
          ? companyFeatures?.sentStatusMessage
          : companyFeatures?.pickUpOnSiteStatusMessage;

        sendMessageToWhatsapp({
          clientNumber: order.client?.phone,
          isUserRole: role === Role.USER,
          message: message ?? '',
          shouldNotifyCustomerWithMessage: companyFeatures?.sellsConfiguration.notifyCustomerWithMessage ?? false,
        });

        updateOrderStatus(order, OrderStatus.READY);
      }}
    />
  );
};