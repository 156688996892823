import React, { useEffect } from "react";
import { Button, Form, FormInstance } from "antd";
import { useSearchParams } from "react-router-dom";
import { useDefinePassword } from "./useDefinePassword";
import { ResetPassword } from "./ResetPassword";
import { Container, Content } from "../../styles";
import { FormInputField } from "@/components/FormInputField";
import { PasswordResetSuccess } from "../PasswordResetSuccess";
import { NotificationDispatcher, NotificationType } from "@/components/Notification";

const passwordRules = [
  {
    required: true,
    message: 'Por favor insira sua senha!',
  },
  {
    min: 6,
    message: 'A senha deve ter pelo menos 6 caracteres!',
  },
];

const confirmPasswordRules = ({ getFieldValue }: Pick<FormInstance, 'getFieldValue'>) => [
  {
    required: true,
    message: 'Por favor, confirme a sua senha!',
  },
  {
    validator(_: unknown, value: string) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('As senhas não coincidem!'));
    },
  },
];

export const DefineNewPassword = () => {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const { reset, isLoading, isSuccess, isError } = useDefinePassword();

  const resetToken = searchParams.get('resetToken');

  useEffect(() => {
    if (isError) {
      NotificationDispatcher({
        message: 'Token expirado ou inválido',
        type: NotificationType.ERROR,
      });
    }
  }, [isError]);

  const sendRequest = (values: unknown) => {
    const formData = values as ResetPassword;
    formData.resetToken = resetToken ?? '';
    reset(formData);
  };

  return (
    <Container>
      <Content>
        <img alt="Logo" src={require('../../../../../assets/images/logo-no-bg.png')} />

        {isSuccess ? <PasswordResetSuccess /> : (
          <Form
            form={form}
            onFinish={sendRequest}
            layout="vertical"
            style={{ marginTop: '30px' }}
          >
            <h1>Definir nova senha</h1>

            <FormInputField
              label="Senha"
              name="password"
              rules={passwordRules}
              type="password"
            />

            <FormInputField
              dependencies={['password']}
              label="Confirmar senha"
              name="confirmPassword"
              rules={confirmPasswordRules(form)}
              type="password"
            />

            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              loading={isLoading}
            >
              <b>Salvar</b>
            </Button>
          </Form>
        )}
      </Content>
    </Container>
  );
};
