import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';
import { SubTitle, Title } from './styles';

export const PasswordResetSuccess = () => {
  const navigate = useNavigate();

  return (
    <Result
      title={<Title>Nova senha criada com sucesso!</Title>}
      subTitle={<SubTitle>Agora você pode efetuar o seu login e usufruir do sistema.</SubTitle>}
      status={'success'}
      extra={
        <Button
          type='primary'
          onClick={() => {
            navigate('/session');
          }}
        >
          <b>Ir para login</b>
        </Button>
      }
    />
  );
}