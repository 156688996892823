import React, { useState } from 'react';
import { Tabs } from 'antd';
import { ContentHeader } from '../../../../components/ContentHeader';
import { WorkSchedule } from './components/WorkSchedule';
import { useCompanyFeatures, useFeatures, useLoggedUser } from '../../../../hooks';
import { MenuOption } from '../../../../models/MenuOption';
import { LogoUpload } from './components/LogoUpload';
import { ClientPaymentList } from './components/ClientPaymentList';
import { AddressStore } from './components/AddressStore';
import { ImageSkeleton } from './components/ImageSkeleton';
import { Integrations } from './components/Integrations';

interface SettingsStorePageProps {
  companyIdOpt?: string;
}

enum Tab {
  LOGO = 'Logomarca',
  LOCATION = 'Localização',
  PAYMENT = 'Métodos de pagamento',
  WORK_SCHEDULE = 'Horário de funcionamento',
  INTEGRATIONS = 'Integrações',
}

const { TabPane } = Tabs;

export const SettingsStorePage = ({ companyIdOpt }: SettingsStorePageProps) => {
  const [selectedTab, setSelectedTab] = useState(Tab.LOGO);

  const { userLogged } = useLoggedUser();
  const { user: { company } } = userLogged;
  const companyId = companyIdOpt ?? company;

  const { companyFeatures, isFetching } = useCompanyFeatures(companyId);
  const { data: features, isFetching: isFetchingFeatures } = useFeatures(companyId);
  const isLoading = isFetching || isFetchingFeatures || companyFeatures === undefined || features === undefined;
  const sellViaWhatsapp = features?.sellViaWhatsapp;

  return (
    <>
      <ContentHeader title={MenuOption.SETTINGS_STORE} />

      {isLoading ? (
        <ImageSkeleton />
      ) : (
        <Tabs
          size="large"
          activeKey={selectedTab.toString()}
          onChange={(key) => setSelectedTab(key as Tab)}
        >
          <TabPane tab={Tab.LOGO} key={Tab.LOGO}>
            <LogoUpload />
          </TabPane>

          <TabPane tab={Tab.WORK_SCHEDULE} key={Tab.WORK_SCHEDULE}>
            <WorkSchedule
              isSellsEnabled={Boolean(sellViaWhatsapp)}
              companyFeatures={companyFeatures}
            />
          </TabPane>

          <TabPane tab={Tab.INTEGRATIONS} key={Tab.INTEGRATIONS}>
            <Integrations companyFeatures={companyFeatures} />
          </TabPane>

          {Boolean(sellViaWhatsapp) && (
            <>
              <TabPane tab={Tab.LOCATION} key={Tab.LOCATION}>
                <AddressStore companyFeatures={companyFeatures} />
              </TabPane>

              <TabPane tab={Tab.PAYMENT} key={Tab.PAYMENT}>
                <ClientPaymentList companyFeatures={companyFeatures} />
              </TabPane>
            </>
          )}
        </Tabs>
      )}
    </>
  );
};
