import { User } from '../../../../models/User';
import { useFetch } from '../../../../hooks/useFetch';

export const USERS = 'users';

export const useUsers = (selectedRole?: string) => {
  const { data, ...rest } = useFetch<User[]>({
    queryKey: USERS,
    url: selectedRole ? `api/users?role=${selectedRole}`: 'api/users',
  });

  return { data, ...rest };
};
