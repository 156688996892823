import React from "react";
import { SettingsSection } from "../..";
import { MenuOption } from "../../../../models/MenuOption";
import { MenuItem } from "../MenuItem";
import { Title } from "../../styles";

import {
  AppstoreAddOutlined,
  BranchesOutlined,
  ClusterOutlined,
  CommentOutlined,
  DatabaseOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  GiftOutlined,
  LinkOutlined,
  PieChartOutlined,
  QrcodeOutlined,
  SettingOutlined,
  TabletOutlined,
  TeamOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Badge, Divider, Menu } from "antd";
import { getClientMenuUrl } from "../../../../utils/Util";

export const clientMenuOptions = (
  collapsed: boolean,
  menuUrl: string,
  stockCounter?: any,
) => {
  return [
    <Title key={SettingsSection.REGISTER} isCollapsed={collapsed}>
      {SettingsSection.REGISTER}
    </Title>,

    MenuItem(MenuOption.CATEGORIES, <PieChartOutlined />),

    MenuItem(
      MenuOption.PRODUCTS, <AppstoreAddOutlined />,
      <>
        <Badge
          count={stockCounter?.outOfStock}
          style={{ marginLeft: '10px' }} />
        <Badge
          count={stockCounter?.lowStock}
          style={{ marginLeft: '10px', backgroundColor: 'orange' }}
        />
      </>
    ),

    MenuItem(MenuOption.COUPON, <GiftOutlined />),

    MenuItem(MenuOption.QR_LINKS, <BranchesOutlined />),

    MenuItem(MenuOption.USER, <TeamOutlined />),

    <Title key={SettingsSection.ORDERS} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.ORDERS}
    </Title>,

    MenuItem(MenuOption.WHATSAPP_ORDERS, <WhatsAppOutlined />),

    MenuItem(MenuOption.HISTORY_ORDERS, <FileSyncOutlined />),

    <Title key={SettingsSection.GENERAL} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.GENERAL}
    </Title>,

    MenuItem(MenuOption.FEEDBACK, <CommentOutlined />),
    MenuItem(MenuOption.BANNER_INFO, <FundProjectionScreenOutlined />),
    MenuItem(MenuOption.QRCODE, <QrcodeOutlined />),
    MenuItem(MenuOption.ACTIVITY_LOG, <DatabaseOutlined />),

    <Title key={SettingsSection.SETTINGS} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.SETTINGS}
    </Title>,
    MenuItem(MenuOption.SETTINGS_PROFILE, <UserOutlined />),
    MenuItem(MenuOption.SETTINGS_STORE, <SettingOutlined />),
    MenuItem(MenuOption.SETTINGS_ORDER, <ClusterOutlined />),
    MenuItem(MenuOption.SETTINGS_DEFAULT_TEXT, <FileTextOutlined />),
    MenuItem(MenuOption.SETTINGS_FEEDBACK, <TabletOutlined />),

    <Divider key="divider" style={{ backgroundColor: 'white' }} />,
    <Menu.Item key={MenuOption.MY_LINK} icon={<LinkOutlined />}>
      <a
        style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}
        href={`${getClientMenuUrl(menuUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {MenuOption.MY_LINK}
      </a>
    </Menu.Item>,
  ];
};
