import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Tabs } from 'antd';

import { ForgotPassword } from './components/ForgotPassword';
import { SignIn } from './components/SignIn';
import { SignUp } from './components/SignUp';

import { Container, Content } from './styles';
import { useAuthHandler } from './hooks/useAuthHandler';

const { TabPane } = Tabs;

export enum UserTab {
  SIGN_IN = 'Entrar',
  SIGN_UP = 'Cadastre-se',
  FORGOT_PASSWORD = 'Esqueceu a senha?',
}

export const SessionPage = () => {
  const { hash: sessionTab } = useLocation();
  const [form] = Form.useForm();
  const { currentTab, isFetching, handleFormSubmit, setCurrentTab } = useAuthHandler(form);

  useEffect(() => {
    let tab = UserTab.SIGN_IN;

    if (sessionTab === '#signup') {
      tab = UserTab.SIGN_UP;
    } else if (sessionTab === '#forgotpassword') {
      tab = UserTab.FORGOT_PASSWORD;
    }

    setCurrentTab(tab);
  }, [sessionTab, setCurrentTab]);

  const onTabChanged = (key: string) => setCurrentTab(key as UserTab);

  return (
    <Container>
      <Content>
        <img alt="Logo" src={require('../../../assets/images/logo-no-bg.png')} />

        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          style={{ marginTop: '30px' }}
        >
          <Tabs activeKey={currentTab?.toString()} onChange={onTabChanged} size='large'>
            <TabPane tab={<b>{UserTab.SIGN_IN}</b>} key={UserTab.SIGN_IN}>
              <SignIn isFetching={isFetching} isRequired={currentTab === UserTab.SIGN_IN} />
            </TabPane>

            <TabPane tab={<b>{UserTab.SIGN_UP}</b>} key={UserTab.SIGN_UP}>
              <SignUp isFetching={isFetching} isRequired={currentTab === UserTab.SIGN_UP} />
            </TabPane>

            <TabPane tab={<b>{UserTab.FORGOT_PASSWORD}</b>} key={UserTab.FORGOT_PASSWORD}>
              <ForgotPassword isFetching={isFetching} isRequired={currentTab === UserTab.FORGOT_PASSWORD} />
            </TabPane>
          </Tabs>
        </Form>
      </Content>
    </Container>
  );
};
