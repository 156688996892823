import { FormInstance } from "antd";
import { NotificationDispatcher, NotificationType } from "@/components/Notification";
import api from "@/services/api";
import { LocalStorageService, TOKEN } from "@/services/LocalStorageService";
import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserTab } from "..";

const showErrorMessage = (message: string) => {
  NotificationDispatcher({
    message,
    type: NotificationType.ERROR,
  });
};

export const useAuthHandler = (form: FormInstance<any>) => {
  const [currentTab, setCurrentTab] = useState<UserTab>();
  const [isFetching, setFetching] = useState<boolean>(false);
  const navigate = useNavigate();

  const doSignIn = async (formData: any) => {
    try {
      const response = await api.post('api/auth/login', formData);
      const data = response.data;
      if (data.success) {
        LocalStorageService.save(TOKEN, data.token);
        navigate('/sysadmin/');
      } else {
        showErrorMessage(data);
      }
    } catch (ex) {
      const error = ex as AxiosError;

      if (error.response) {
        switch (error.response.status) {
          case 403:
            const isUserInactive = error.response.data.error === 'User is not active';
            const message = isUserInactive ? 'Usuário inativo' : 'A sua empresa está inativa. Procure o administrador do sistema.';

            showErrorMessage(message);
            break;
          default:
            showErrorMessage('Email e/ou senha inválidos');
            break;
        }
      } else {
        showErrorMessage(error.message);
      }
    } finally {
      setFetching(false);
    }
  };

  const doSignUp = async (formData: any) => {
    try {
      const response = await api.post('api/auth/register', formData);
      const data = response.data;
      if (data.success) {
        LocalStorageService.save(TOKEN, data.token);
        navigate('/sysadmin/');
      } else {
        showErrorMessage(data);
      }
    } catch (ex) {
      const error = ex as AxiosError;

      if (error.response) {
        switch (error.response.data.error) {
          case 'Invalid Access Code':
            showErrorMessage('Código de confirmação inválido.');
            break;
          case 'Your company is not active':
            showErrorMessage(
              'A sua empresa está inativa. Procure o administrador do sistema.'
            );
            break;
          case 'Duplicate field value entered':
            showErrorMessage('Email já cadastrado.');
            break;
          default:
            showErrorMessage(
              'Error ao tentar efetuar o cadastro. Por favor, verifique se o email é válido ou se o código está correto.'
            );
            break;
        }
      } else {
        showErrorMessage(error.message);
      }
    } finally {
      setFetching(false);
    }
  };

  const requestResetPassword = async (formData: any) => {
    try {
      const response = await api.post('api/auth/forgotpassword', formData);

      if (response.status === 204) {
        NotificationDispatcher({
          message: "Por favor verifique o seu e-mail para recuperar a senha.",
          type: NotificationType.WARNING,
        });
      } else {
        showErrorMessage("Erro ao tentar enviar email, por favor tente novamente.");
      }
    } catch (ex) {
      const error = ex as AxiosError;
      showErrorMessage(error.message);
    } finally {
      setFetching(false);
    }
  };

  const handleFormSubmit = (values: any) => {
    setFetching(true);

    switch (currentTab) {
      case UserTab.SIGN_IN:
        doSignIn(values);
        break;
      case UserTab.SIGN_UP:
        doSignUp(values);
        break;
      case UserTab.FORGOT_PASSWORD:
        requestResetPassword(values);
        break;
    }

    form.resetFields();
  };

  return { currentTab, isFetching, handleFormSubmit, setCurrentTab }
};