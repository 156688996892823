import React from 'react';
import { Form, Input } from 'antd';
import InputMask from 'react-input-mask';

export declare type InternalNamePath = (string | number)[];
export declare type NamePath = string | number | InternalNamePath;

type InputTextAreaProps = {
  showCount?: boolean;
  rows?: number;
};

const DEFAULT_RULE = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

interface FormInputFieldProps {
  dependencies?: NamePath[];
  disableInput?: boolean;
  help?: string;
  inputTextAreaProps?: InputTextAreaProps;
  isInputArea?: boolean;
  label?: string | React.ReactNode;
  mask?: string;
  maxLength?: number;
  name?: string;
  namePath?: NamePath;
  normalize?: any;
  onInput?: React.FormEventHandler<HTMLInputElement> | undefined
  placeholder?: string;
  prefix?: string | React.ReactNode;
  inputPrefix?: string | React.ReactNode;
  rules?: any;
  style?: React.CSSProperties;
  suffix?: string | React.ReactNode;
  type?: any;
  useDefaultRules?: boolean;
};

export const FormInputField = ({
  dependencies,
  disableInput,
  help,
  inputTextAreaProps,
  isInputArea,
  label,
  mask = '',
  maxLength,
  name,
  namePath,
  normalize,
  onInput,
  placeholder,
  prefix,
  inputPrefix,
  rules,
  style,
  suffix,
  type,
  useDefaultRules,
}: FormInputFieldProps) => {
  const renderInput = () => {
    return (
      <Input
        addonBefore={prefix}
        addonAfter={suffix}
        disabled={disableInput}
        id={name}
        maxLength={maxLength}
        onInput={onInput}
        placeholder={placeholder}
        style={{ height: '40px' }}
        type={type ?? 'text'}
        prefix={inputPrefix}
      />
    );
  }

  return (
    <Form.Item
      dependencies={dependencies}
      help={help}
      label={<label className="label-input-field">{label}</label>}
      name={name ?? namePath}
      normalize={normalize}
      rules={useDefaultRules ? DEFAULT_RULE : rules}
      style={style ? style : { marginTop: '20px' }}
    >
      {isInputArea ? (
        <Input.TextArea
          disabled={disableInput}
          id={name}
          maxLength={maxLength}
          placeholder={placeholder}
          rows={inputTextAreaProps?.rows}
          showCount={inputTextAreaProps?.showCount}
        />
      ) : (
        mask ?
          <InputMask mask={mask}>
            {() => renderInput()}
          </InputMask> : (
            renderInput()
          )
      )}
    </Form.Item>
  );
};
