import { useCallback } from "react";
import { useMutation } from "react-query";
import { UserService } from "../components/services/UserService";

export const useResendInvitation = () => {
  const { mutate } = useMutation(
    (userId: string) => UserService.resendInvitation(userId)
  );

  const sendInvite = useCallback((userId, { onSuccess, onError }) => {
    mutate(userId, {
      onSuccess,
      onError,
    });
  },
    [mutate]
  );

  return { sendInvite };
};
