import React from "react";
import { Divider, Menu } from 'antd';
import { MenuOption } from "../../../../models/MenuOption";
import { Role } from "../../../../models/Enum";
import { useLoggedUser } from "../../../../hooks";
import { useSidebarMenuKeySelection } from "../../hooks/useSidebarMenuKeySelection";

import minLogoImg from '../../../../assets/images/icon-qrcode.png';
import logoImg from '../../../../assets/images/logo-bg-transparent-text-white.png';
import { TutorialMenu } from "../TutorialMenu";
import { LeaveMenu } from "../LeaveMenu";
import { useGetMenuItemsByRole } from "../../hooks/useGetMenuItemsByRole";

interface SideBarMenuContentProps {
  collapsed?: boolean;
  onMenuSelected?(): void;
  contactsBadgeCounter?: number;
  stockCounter?: any;
};

export const SideBarMenuContent = ({
  collapsed = false,
  contactsBadgeCounter = 0,
  onMenuSelected,
  stockCounter = 0,
}: SideBarMenuContentProps) => {

  const { currentSelectedMenu, handleMenuSelection } = useSidebarMenuKeySelection();
  const { userLogged } = useLoggedUser();
  const { user: { role } } = userLogged;
  const { menuItems, defaultSelectedKeys } = useGetMenuItemsByRole({ collapsed, contactsBadgeCounter, stockCounter });

  const handleMenuClick = (selectedMenu: string) => {
    if (onMenuSelected) {
      onMenuSelected();
    }

    if (selectedMenu === MenuOption.MY_LINK) return;

    const menuSelected = selectedMenu as MenuOption;
    handleMenuSelection(menuSelected);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <>
      <div className="sidebar-logo-container">
        <img
          className={collapsed ? 'admin-sidebar-logo_collapsed' : "admin-sidebar-logo"}
          src={collapsed ? minLogoImg : logoImg}
          alt="QRcode Preferido"
        />
      </div>

      <Menu
        defaultSelectedKeys={defaultSelectedKeys()}
        style={{ background: '#15395b' }}
        mode="inline"
        onClick={({ key }) => handleMenuClick(key)}
        theme="dark"
        selectedKeys={currentSelectedMenu ? [currentSelectedMenu] : undefined}
      >
        {menuItems()}

        {role !== Role.USER && <Divider key="divider" style={{ backgroundColor: 'white' }} />}

        {(role !== Role.KITCHEN && role !== Role.DRIVER) && (
          <TutorialMenu />
        )}

        <LeaveMenu />
      </Menu>
    </>
  );
}
