import React from "react";

export const TutorialMenu = () => (
  <li className="ant-menu-item" role="menuitem" style={{ paddingLeft: '24px' }}>
    <span role="img" aria-label="bulb" className="anticon anticon-bulb ant-menu-item-icon">
      <svg viewBox="64 64 896 896" focusable="false" data-icon="bulb" width="1em" height="1em" fill="currentColor" aria-hidden="true">
        <path d="M632 888H392c-4.4 0-8 3.6-8 8v32c0 17.7 14.3 32 32 32h192c17.7 0 32-14.3 32-32v-32c0-4.4-3.6-8-8-8zM512 64c-181.1 0-328 146.9-328 328 0 121.4 66 227.4 164 284.1V792c0 17.7 14.3 32 32 32h264c17.7 0 32-14.3 32-32V676.1c98-56.7 164-162.7 164-284.1 0-181.1-146.9-328-328-328zm127.9 549.8L604 634.6V752H420V634.6l-35.9-20.8C305.4 568.3 256 484.5 256 392c0-141.4 114.6-256 256-256s256 114.6 256 256c0 92.5-49.4 176.3-128.1 221.8z"></path>
      </svg>
    </span>
    <span className="ant-menu-title-content">
      <a href="https://drive.google.com/file/d/18j-PaNqceCA9OmmDuVCExSV9D4sKWyIx/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontWeight: ' bold', fontSize: '14px' }}>Tutorial</a>
    </span>
  </li>
);
