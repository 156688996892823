import React from "react";
import { HourglassOutlined } from '@ant-design/icons';
import { TableGenericAction } from "../../../../../../../../components/TableGenericAction";
import { WhatsappOrder } from "../../../../../../../../models/DataResponse";
import { useCompanyFeatures, useLoggedUser } from "../../../../../../../../hooks";
import { sendMessageToWhatsapp } from "../../../../../Util/SendWhatsappMessage";
import { OrderStatus } from "../../../../../../../../models/OrderStatus";
import { Role } from "../../../../../../../../models/Enum";
import { useUpdateOrderStatus } from "../../../../../hooks/useUpdateOrderStatus";

interface ExecuteActionButtonProps {
  order: WhatsappOrder;
  toggleAudio(): void;
}

export const ExecuteActionButton = ({ order, toggleAudio }: ExecuteActionButtonProps) => {
  const { userLogged } = useLoggedUser();
  const { user: { company, role } } = userLogged;
  const { companyFeatures } = useCompanyFeatures(company);
  const { updateOrderStatus } = useUpdateOrderStatus();

  return (
    <TableGenericAction
      dashed
      icon={<HourglassOutlined style={{ fontSize: '18px' }} />}
      label="Aceitar"
      style={{ backgroundColor: '#edbf09', color: '#fff' }}
      onClick={() => {
        toggleAudio();

        sendMessageToWhatsapp({
          clientNumber: order.client?.phone,
          isUserRole: role === Role.USER,
          message: companyFeatures?.preparationStatusMessage ?? '',
          shouldNotifyCustomerWithMessage: companyFeatures?.sellsConfiguration.notifyCustomerWithMessage ?? false,
        });

        updateOrderStatus(order, OrderStatus.IN_PROGRESS);
      }}
    />
  );
};
